<template>
    <div class="row text-xs-left" ref="clipboard">
        <div class="xs6 pr-3">
            <div class="row font-16 font-bold mb-1">Thông tin vé</div>

            <div
                class="pl-3 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Giá vé</div>
                <div class="xs8 font-16 font-medium color-success">
                    {{ $Helper.Common.moneyFormat(item.giaVe) }} đồng
                </div>
            </div>
            <div
                class="pl-3 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Thời gian mua vé</div>
                <div class="xs8 font-16 font-medium">
                    {{ $Helper.Common.formatDateTime(item.thoiGianMuaVe) }}
                </div>
            </div>
            <div
                class="pl-3 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Mã tra cứu</div>
                <div class="xs8 font-16 font-medium">
                    {{ item.maTraCuu }}
                    <DxButton
                        type="default"
                        styling-mode="text"
                        hint="Sao chép"
                        icon="mdi mdi-content-copy"
                        @click="SaoChep(item.maTraCuu)"
                    />
                </div>
            </div>
            <div
                class="pl-3 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Link tra cứu</div>
                <div class="xs8 font-16 font-medium">
                    <a :href="item.linkTraCuuHDDT" target="_blank">
                        {{ item.linkTraCuuHDDT }}
                    </a>
                </div>
            </div>
            <div
                class="pl-3 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Trạng thái vé</div>
                <div
                    class="xs8 font-16 font-medium"
                    :style="`color: ${item.maMauTrangThai}`"
                >
                    {{ item.tenTrangThai }}
                </div>
            </div>

            <div class="row font-16 font-bold mb-1 mt-3">
                Thông tin hành khách
            </div>
            <div
                class="pl-3 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Số điện thoại</div>
                <div class="xs8 font-16 font-medium">
                    {{ item.soDienThoaiHanhKhach }}
                </div>
            </div>
            <div
                class="pl-3 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Họ tên</div>
                <div class="xs8 font-16 font-medium">
                    {{ item.hoTenHanhKhach }}
                </div>
            </div>
            <div
                class="pl-3 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Email</div>
                <div class="xs8 font-16 font-medium">
                    {{ item.emailHanhKhach }}
                </div>
            </div>
        </div>
        <div class="xs6 pl-2">
            <div class="row font-16 font-bold mb-1">
                Thông tin chuyến đi
            </div>
            <div
                class="pl-3 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Mã chuyến đi</div>
                <div class="xs8 font-16 font-medium">
                    {{ item.maChuyenDi }}
                    <DxButton
                        type="default"
                        styling-mode="text"
                        hint="Sao chép"
                        icon="mdi mdi-content-copy"
                        v-if="item.maChuyenDi"
                        @click="SaoChep(item.maChuyenDi)"
                    />
                </div>
            </div>
            <div
                class="pl-3 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Biển số xe</div>
                <div class="xs8 font-16 font-medium">
                    {{ item.bienKiemSoat }}
                </div>
            </div>
            <div
                class="pl-3 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Tuyến vận chuyển</div>
                <div class="xs8 font-16 font-medium">
                    {{
                        item.tenDiemXuatPhat +
                        " - " +
                        item.tenDiemKetThuc +
                        `(${item.maLuongTuyen})`
                    }}
                </div>
            </div>
            <div
                class="pl-3 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Thời gian khởi hành</div>
                <div class="xs8 font-16 font-medium">
                    {{ $Helper.Common.formatDateTime(item.thoiGianKhoiHanh) }}
                </div>
            </div>

            <div class="row font-16 font-bold mb-1 mt-3">
                Thông tin doanh nghiệp
            </div>
            <div
                class="pl-3 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Tên doanh nghiệp</div>
                <div class="xs8 font-16 font-medium">
                    {{ item.tenDoanhNghiep }}
                </div>
            </div>
            <div
                class="pl-3 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Mã số thuế doanh nghiệp</div>
                <div class="xs8 font-16 font-medium">
                    {{ item.maSoThueDoanhNghiep }}
                    <DxButton
                        type="default"
                        styling-mode="text"
                        hint="Sao chép"
                        icon="mdi mdi-content-copy"
                        v-if="item.maSoThueDoanhNghiep"
                        @click="SaoChep(item.maSoThueDoanhNghiep)"
                    />
                </div>
            </div>
            <div
                class="pl-3 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Số điện thoại doanh nghiệp</div>
                <div class="xs8 font-16 font-medium">
                    {{
                        $Helper.Common.formatPhone(item.soDienThoaiDoanhNghiep)
                    }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
export default {
    components: {
        DxButton,
    },
    props: {
        item: { type: Object, default: () => {} },
    },
    methods: {
        ThongBaoToast(type, msg) {
            let modelToast = {
                isVisible: true,
                message: msg,
                type: type,
            };
            this.emitter.emit("onToast", modelToast);
        },
        async SaoChep(text) {
            try {
                await this.$toClipboard(text);
                this.ThongBaoToast("success", `Sao chép ${text} thành công!`);
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.ThongBaoToast(
                    "error",
                    "Lấy thông tin vé thất bại! Lỗi hệ thống"
                );
            }
        },
    },
};
</script>

<style>
</style>